<template>
    <div id="show-box" :class="{'show-box-text-context': renderType === 1,
       'show-box-mind-map': renderType === 2}">
        <vditor-show v-if="renderType === 1" :content="content" :anchor="anchor"/>
        <mind-map-show v-if="renderType === 2" :content="content" />
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import 'vditor/dist/index.css'
    import MindMapShow from '@/components/notes/MindMapShow.vue';
    import VditorShow from '@/components/vditor-notes/Show.vue';

    export default defineComponent({
        name: "NoteView",
        components: {
            VditorShow,
            MindMapShow
        },
        props: {
            id: String,
            name: String,
            content: String,
            renderType: Number,
            anchor: String
        }

    })
</script>

<style scoped lang="less">
    @max-preview-width: 666px;

    html,
    body,
    #show-box {
        margin: 0;
        height: 100%;
        font-family: "Helvetica Neue", Arial, sans-serif;
        color: #333;
    }

    #show-box {
        margin-left: auto;
        margin-right: auto;
    }

    .show-box-text-context {
      max-width: @max-preview-width + 40px;
    }

    .show-box-mind-map {

    }

    #vditor {
        padding-right: 20px;
        text-align: justify;
    }

    #title {
        padding: 0 20px;
        text-align: justify;
    }
</style>