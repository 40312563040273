import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a7ff62b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "show-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_note_view_header = _resolveComponent("note-view-header")!
  const _component_note_view = _resolveComponent("note-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_note_view_header, {
      noteId: _ctx.id,
      name: _ctx.name,
      engineType: _ctx.engineType,
      renderType: _ctx.renderType,
      defaultRendering: _ctx.defaultRendering,
      renderTypeChanged: _ctx.renderTypeChanged,
      defaultRenderingChanged: _ctx.defaultRenderingChanged,
      "hide-title": _ctx.hideViewTitle
    }, null, 8, ["noteId", "name", "engineType", "renderType", "defaultRendering", "renderTypeChanged", "defaultRenderingChanged", "hide-title"]),
    _createVNode(_component_note_view, {
      id: _ctx.id,
      name: _ctx.name,
      content: _ctx.content,
      renderType: _ctx.renderType,
      anchor: _ctx.anchor
    }, null, 8, ["id", "name", "content", "renderType", "anchor"])
  ]))
}