
    import {defineComponent, onMounted, reactive, toRefs} from 'vue';
    import NoteView from "@/components/notes/NoteView.vue";
    import useNotes from "@/composables/useNotes";
    import {apiResponseErrorHandle} from "@/composables/errorHandler";
    import {MARKDOWN_ENGINE_BUILD_IN} from "@/constants/note";
    import NoteViewHeader from "@/components/notes/NoteViewHeader.vue";
    import {useStore} from "vuex";
    import useShowNotePageData from '@/composables/useShowNotePageData';

    export default defineComponent({
        name: "Show",
        components: {
            NoteViewHeader,
            NoteView
        },
        setup() {
            const {getNote} = useNotes();
            const {parseNoteId} = useShowNotePageData();
            const hideViewTitle = localStorage.getItem('folderModeViewTitleShow') === 'no';

            const state = reactive({
                id: 0,
                note: {
                    name: "",
                    content: ""
                },
                defaultRendering: 1,
                renderType: 1,
                renderingDialogVisible: false,
                shareDialogVisible: false
            });

            state.id = parseNoteId();

            onMounted(() => {
                get(state.id);
            })

            const renderTypeChanged = (type: number) => {
                state.renderType = type
            }

            const get = (noteId: number | null) => {
                noteId && getNote(noteId).then(function(response) {
                    state.note = response.data.note;
                    state.defaultRendering = response.data.note.defaultRendering;
                    state.renderType = state.defaultRendering;
                }).catch(apiResponseErrorHandle('获取数据失败'))
            }

            const defaultRenderingChanged = (renderingValue: number) => {
                state.defaultRendering = renderingValue;
            }

            return {
                ...toRefs(state),
                renderTypeChanged,
                defaultRenderingChanged,
                engineType: MARKDOWN_ENGINE_BUILD_IN,
                hideViewTitle
            }
        }
    })
