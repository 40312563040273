
    import { defineComponent, onMounted, reactive, toRefs } from 'vue';
    import NoteViewHeader from "@/components/notes/NoteViewHeader.vue";
    import NoteView from "@/components/vditor-notes/NoteView.vue";
    import {apiResponseErrorHandle} from "@/composables/errorHandler";
    import useNotes from "@/composables/useNotes";
    import {MARKDOWN_ENGINE_VDITOR} from "@/constants/note";
    import {useStore} from "vuex";
    import useShowNotePageData from "@/composables/useShowNotePageData";

    export default defineComponent({
        name: "Show",
        components: {
            NoteViewHeader,
            NoteView
        },
        setup() {
            const {getNote} = useNotes();
            const {parseNoteId, getHash} = useShowNotePageData();
          const hideViewTitle = localStorage.getItem('folderModeViewTitleShow') === 'no';

            const state = reactive({
                id: 0,
                name: "",
                content: "",
                defaultRendering: 1,
                renderType: 1,
                renderingDialogVisible: false,
                anchor: '',
                shareDialogVisible: false
            });

            state.id = parseNoteId();
            state.anchor = getHash();

            onMounted(() => {
                get(state.id);
            })

            const renderTypeChanged = (type: number) => {
                state.renderType = type
            }

            const get = (noteId: number | null) => {
                noteId && getNote(noteId).then(function(response) {
                    state.name = response.data.note.name;
                    state.content = response.data.note.content;
                    state.defaultRendering = response.data.note.defaultRendering;
                    state.renderType = state.defaultRendering;
                }).catch(apiResponseErrorHandle('获取数据失败'))
            }

            const defaultRenderingChanged = (renderingValue: number) => {
                state.defaultRendering = renderingValue;
            }

            return {
                ...toRefs(state),
                renderTypeChanged,
                defaultRenderingChanged,
                engineType: MARKDOWN_ENGINE_VDITOR,
                hideViewTitle
            }
        }
    })
