<template>
    <div id="show-box" :class="{'show-box-text-context': renderType === 1,
       'show-box-mind-map': renderType === 2}">
        <content-show v-if="renderType === 1" :content="note.content" />
        <mind-map-show v-if="renderType === 2" :content="note.content" />
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import ContentShow from '@/components/notes/ContentShow.vue';
    import MindMapShow from '@/components/notes/MindMapShow.vue';

    export default defineComponent({
        name: "NoteView",
        components: {
            ContentShow,
            MindMapShow
        },
        props: {
            note: {
                id: String,
                name: String,
            },
            renderType: Number
        }

    })
</script>

<style scoped lang="less">
    #show-box {
        margin: 0;
        height: 100%;
        font-family: "Helvetica Neue", Arial, sans-serif;
        color: #333;
    }

    #show-box {
        margin-left: auto;
        margin-right: auto;
    }

    .show-box-text-context {
      max-width: 706px;
    }

    .show-box-mind-map {

    }

    #title {
        padding: 0 20px;
        text-align: justify;
    }
</style>